import React from 'react';
import { FaFacebook, FaTwitter, FaLinkedin, FaWhatsapp, FaReddit, FaWeibo, FaWeixin, FaLine } from 'react-icons/fa';
import styled from 'styled-components';

const SocialShare = styled.div`
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    justify-content: center;

    a {
        color: #333;
        transition: transform 0.3s ease, color 0.3s ease;

        &:hover {
            transform: scale(1.2);
            color: #2768CB;
        }
    }

    svg {
        width: 30px;
        height: 30px;
    }
`;

function SocialShareButtons({ title, url }) {
    const encodedUrl = encodeURIComponent(url);
    const encodedTitle = encodeURIComponent(title);

    return (
        <SocialShare>
            <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`} target="_blank" rel="noopener noreferrer" aria-label="Share on Facebook">
                <FaFacebook />
            </a>
            <a href={`https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}`} target="_blank" rel="noopener noreferrer" aria-label="Share on Twitter">
                <FaTwitter />
            </a>
            <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=${encodedTitle}`} target="_blank" rel="noopener noreferrer" aria-label="Share on LinkedIn">
                <FaLinkedin />
            </a>
            <a href={`https://api.whatsapp.com/send?text=${encodedTitle}%20${encodedUrl}`} target="_blank" rel="noopener noreferrer" aria-label="Share on WhatsApp">
                <FaWhatsapp />
            </a>
            <a href={`https://www.reddit.com/submit?url=${encodedUrl}&title=${encodedTitle}`} target="_blank" rel="noopener noreferrer" aria-label="Share on Reddit">
                <FaReddit />
            </a>
            <a href={`https://service.weibo.com/share/share.php?url=${encodedUrl}&title=${encodedTitle}`} target="_blank" rel="noopener noreferrer" aria-label="Share on Weibo">
                <FaWeibo />
            </a>
            <a href={`https://timeline.line.me/social-plugin/share?url=${encodedUrl}`} target="_blank" rel="noopener noreferrer" aria-label="Share on Line">
                <FaLine />
            </a>
            <a href={`weixin://dl/chat?text=${encodedTitle}%20${encodedUrl}`} target="_blank" rel="noopener noreferrer" aria-label="Share on WeChat">
                <FaWeixin />
            </a>
        </SocialShare>
    );
}

export default SocialShareButtons;
