import React from 'react';
import styled from 'styled-components';

const AdContainer = styled.div`
    margin: 2rem auto;
    padding: 1.5rem;
    background-color: #F7F9FC;
    border-radius: 0.5rem;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
`;

function AdBanner() {
    return (
        <AdContainer>
            {/* Aquí podrías integrar el código del anuncio real */}
            <p>Ad</p>
        </AdContainer>
    );
}

export default AdBanner;
