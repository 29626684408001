import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import GameList from './components/GameList';
import GameDetail from './components/GameDetail';
import MainMenu from './components/MainMenu';
import Footer from './components/Footer';
import SearchResults from './components/SearchResults';
import CookieConsent from './components/CookieConsent';
import PrivacyPolicy from './components/PrivacyPolicy';
import ErrorBoundary from './components/ErrorBoundary';

function App() {
    const [categories, setCategories] = useState([]);

    // Cargar categorías de WordPress al montar el componente
    useEffect(() => {
        fetch('/wp-json/wp/v2/categories?per_page=100')
            .then(response => response.json())
            .then(data => setCategories(data))
            .catch(error => console.error('Error fetching categories:', error));
    }, []);

    return (
        <BrowserRouter>
            <ErrorBoundary>
                <MainMenu allCategories={categories}/>
                <Routes>
                    <Route path="/" element={<GameList allCategories={categories}/>} />
                    <Route path="/category/:categorySlug" element={<GameList allCategories={categories}/>} />
                    <Route path="/search" element={<SearchResults />} />
                    <Route path="/privacy" element={<PrivacyPolicy />} />
                    {/* Ruta estática para detalles de cada juego */}
                    <Route path="/:categorySlug/:gameName" element={<GameDetail allCategories={categories} />} />
                </Routes>
                <Footer />            
                <CookieConsent />
            </ErrorBoundary>
        </BrowserRouter>
    );
}

export default App;
