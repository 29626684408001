import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    text-align: center;
`;

const GameGrid = styled.div`
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(10em, 1fr));
    @media (max-width: 1024px) {
        grid-template-columns: repeat(auto-fit, minmax(10em, 1fr));
    }
    @media (max-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 480px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

const GameItem = styled.div`
    background-color: rgb(39, 104, 203);
    border-radius: 0.5rem;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
    }

    img {
        width: 100%;
        height: auto;
        border-bottom: 0.125rem solid #f0f0f0;
    }

    h2 {
        font-size: 1rem;
        color: #333;
        padding: 0.75rem 1rem;
        margin: 0;
        background-color: #2768CB;
        color: #FFFFFF;
        text-transform: uppercase;
        letter-spacing: 0.05rem;
    }

    a {
        color: inherit;
        text-decoration: none;
        display: block;
    }
`;

const TitleContainer = styled.div`
    height: 100%;
    align-items: center;
`;

const LoadMoreButton = styled.button`
    margin-top: 2rem;
    padding: 0.8rem 2rem;
    font-size: 1rem;
    color: #FFFFFF;
    background-color: #2768CB;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);

    &:hover {
        background-color: #F62F2F;
        transform: translateY(-3px);
    }
`;

function GameList({allCategories}) {
    const { categorySlug } = useParams();
    const [games, setGames] = useState([]);
    const [categories, setCategories] = useState({});
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const location = useLocation();

    // Constantes para controlar el número de juegos en la primera carga y en las siguientes
    const INITIAL_LOAD_COUNT = 18;
    const SUBSEQUENT_LOAD_COUNT = 6;
    const [gamesPerPage, setGamesPerPage] = useState(INITIAL_LOAD_COUNT);

    // Resetear estado cuando se monta el componente o cambia la categoría
    useEffect(() => {
        setGames([]);
        setPage(1);
        setHasMore(true);
        setGamesPerPage(INITIAL_LOAD_COUNT); // Reiniciar a 18 juegos en la primera carga
    }, [location.pathname]);

    // Obtener categorías para mapear ID a slug
    useEffect(() => {
        if (allCategories) {
            const categoryMap = allCategories.reduce((acc, category) => {
                acc[category.slug] = category.id;
                return acc;
            }, {});
            setCategories(categoryMap);    
        }
    }, [allCategories]);

    // Función para cargar juegos
    const loadGames = () => {
        const categoryId = categories[categorySlug];
        const categoryFilter = categoryId ? `&categories=${categoryId}` : '';

        if (loading || !hasMore) return;

        setLoading(true);
        
        fetch(`/wp-json/wp/v2/posts?per_page=${gamesPerPage}&page=${page}${categoryFilter}`)
            .then(response => response.json())
            .then(data => {
                setLoading(false);
                if (data.length < gamesPerPage) {
                    setHasMore(false);
                }
                setGames(prevGames => [...prevGames, ...data]);
                // Cambia a SUBSEQUENT_LOAD_COUNT después de la primera carga
                setGamesPerPage(SUBSEQUENT_LOAD_COUNT);
            })
            .catch(error => {
                setLoading(false);
                console.error('Error fetching games:', error);
            });
    };

    // Ejecutar carga de juegos cada vez que cambien `page`, `categorySlug` o `categories`
    useEffect(() => {
        if (categories && Object.keys(categories).length > 0) {
            loadGames();
        }
    }, [page, categorySlug, categories]);

    const loadMoreGames = () => {
        setPage(prevPage => prevPage + 1);
    };

    return (
        <Wrapper>
            <GameGrid>
                {games.map(game => {
                    const categorySlug = Object.keys(categories).find(
                        key => categories[key] === game.categories[0]
                    ) || 'unknown-category';
                    return (
                        <GameItem key={game.id}>
                            <Link to={`/${categorySlug}/${game.slug}`} title={`Play ${game.title.rendered}`}>
                                <img
                                    src={game.meta.thumbnail_url ? game.meta.thumbnail_url[0] : ''}
                                    alt={game.title.rendered}
                                />
                                <TitleContainer>
                                    <h2>{game.title.rendered}</h2>
                                </TitleContainer>
                            </Link>
                        </GameItem>
                    );
                })}
            </GameGrid>
            {hasMore && !loading && (
                <LoadMoreButton onClick={loadMoreGames}>
                    + Load More Games
                </LoadMoreButton>
            )}
            {loading && <p>Loading...</p>}
        </Wrapper>
    );
}

export default GameList;