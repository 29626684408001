/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-11-05",
    versionChannel: "nightly",
    buildDate: "2024-11-05T00:06:22.689Z",
    commitHash: "31e3d0d3213255ff2f5b4d7377f8f7c4fcdfd62b",
};
