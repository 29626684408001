import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';

const FooterContainer = styled.footer`
    padding: 2rem;
    background-color: #f7f9fc;
    color: #333;
    text-align: center;
    border-top: 1px solid #e0e0e0;
`;

const FooterLinks = styled.div`
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 1rem;
`;

const FooterLink = styled.a`
    color: #2768CB;
    text-decoration: none;
    font-size: 1rem;
    cursor: pointer;

    &:hover {
        color: #F62F2F;
    }
`;

const ExternalLink = styled.a`
    color: #2768CB;
    text-decoration: none;
    font-size: 1rem;

    &:hover {
        color: #F62F2F;
    }
`;

const ContactButton = styled.button`
    color: #2768CB;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;

    &:hover {
        color: #F62F2F;
    }
`;

const ContactModal = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    z-index: 100;
    width: 90%;
    max-width: 500px;
`;

const CloseButton = styled.button`
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
`;

const ContactForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const Input = styled.input`
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    width: 100%;
`;

const TextArea = styled.textarea`
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    width: 100%;
    resize: vertical;
`;

const SubmitButton = styled.button`
    padding: 0.75rem;
    background-color: #2768CB;
    color: #fff;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;

    &:hover {
        background-color: #F62F2F;
    }
`;

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 50;
`;

const Copyright = styled.p`
    font-size: 0.9rem;
    color: #666;
    margin-top: 1rem;
`;

function Footer() {
    const [isContactOpen, setContactOpen] = useState(false);
    const [formData, setFormData] = useState({ name: '', email: '', message: '' });
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const navigate = useNavigate();

    const openContactModal = () => setContactOpen(true);
    const closeContactModal = () => {
        setContactOpen(false);
        setError(null);
        setSuccess(null);
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setSuccess(null);

        try {
            const response = await fetch('/wp-json/contact-form/send', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setSuccess("Your message has been sent successfully!");
                setFormData({ name: '', email: '', message: '' });
            } else {
                const errorData = await response.json();
                setError(errorData.message || "Failed to send message.");
            }
        } catch (err) {
            setError("An error occurred. Please try again.");
        }
    };

    return (        
        <FooterContainer>
            <FooterLinks>
                <FooterLink onClick={() => navigate("/privacy")}>Privacy</FooterLink>
                <ContactButton onClick={openContactModal}>Contact</ContactButton>
                <ExternalLink href="http://ilmaregames.com.ar" target="_blank">ilMareGames.com.ar</ExternalLink>
            </FooterLinks>
            <Copyright>
                &copy; 2010 - {new Date().getFullYear()} GooGameZ
            </Copyright>
            {isContactOpen && (
                <>
                    <Overlay onClick={closeContactModal} />
                    <ContactModal>
                        <CloseButton onClick={closeContactModal}>&times;</CloseButton>
                        <h2>Contact Us</h2>
                        <ContactForm onSubmit={handleSubmit}>
                            <Input
                                type="text"
                                name="name"
                                placeholder="Your Name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                            <Input
                                type="email"
                                name="email"
                                placeholder="Your Email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                            <TextArea
                                name="message"
                                placeholder="Your Message"
                                rows="5"
                                value={formData.message}
                                onChange={handleChange}
                                required
                            />
                            <SubmitButton type="submit">Send Message</SubmitButton>
                        </ContactForm>
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        {success && <p style={{ color: 'green' }}>{success}</p>}
                    </ContactModal>
                </>
            )}
        </FooterContainer>
    );
}

export default Footer;
