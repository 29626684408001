import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    max-width: 800px;
    margin: 2rem auto;
    padding: 1rem;
    color: #333;
    line-height: 1.6;
`;

const SectionTitle = styled.h2`
    font-size: 1.5rem;
    color: #2768CB;
    margin-top: 1.5rem;
`;

function PrivacyPolicy() {
    return (
        <Wrapper>
            <h1>Privacy Policy for googamez.com</h1>
            <p>
                The privacy of our visitors at googamez.com is very important to us.
            </p>
            <p>
                At googamez.com, we recognize the importance of protecting your personal information. This document outlines the types of personal information we collect and record when you visit our website, and how we use and protect that information. We never sell your personal information to third parties.
            </p>

            <SectionTitle>Log Files</SectionTitle>
            <p>
                Like most websites, we collect and use data in log files. This data includes your IP address, your Internet Service Provider (ISP), the browser used to access our site (such as Chrome, Firefox, or Safari), the time of your visit, and the pages visited throughout our site. This information is used to analyze trends, administer the site, and understand user interaction patterns. We do not link this data to personally identifiable information.
            </p>

            <SectionTitle>Cookies and Web Beacons</SectionTitle>
            <p>
                We use cookies to store information about your personal preferences when you visit our site. This may include displaying a popup only once per visit or enabling certain site features. We also use third-party services to show ads on googamez.com. Some advertisers may use cookies or web beacons to collect data such as your IP address, your ISP, the browser used to access our site, and in some cases, whether Flash is installed. This information is generally used for geotargeting purposes (showing location-specific ads) or displaying relevant ads based on visited sites.
            </p>

            <SectionTitle>DoubleClick DART Cookies</SectionTitle>
            <p>
                We may use Google DART cookies for ad serving through Google’s DoubleClick. This cookie is placed on your computer when you browse sites using DoubleClick advertising, including some Google AdSense ads. It allows ads to be served to you based on your interests by tracking visits to this and other websites. DART uses “non-personally identifiable information” and does not track personal details such as your name, email address, or physical address. You can opt out of interest-based advertising by visiting <a href="https://policies.google.com/technologies/ads" target="_blank" rel="noopener noreferrer">Google’s Ads Settings</a>.
            </p>

            <SectionTitle>User Analytics</SectionTitle>
            <p>
                At googamez.com, we use analytics tools to track user behavior and interactions on our site. This includes metrics such as visit duration, pages viewed, and navigation patterns. This data helps us improve user experience and optimize our site. All information gathered is aggregated and does not include personally identifiable information.
            </p>

            <SectionTitle>Cookie Management</SectionTitle>
            <p>
                You may choose to disable cookies through your individual browser options or manage preferences in Internet security programs. However, disabling cookies may affect your ability to interact with our site and other websites. This could include the inability to log in to services or access certain features. 
            </p>
            <p>
                Deleting cookies does not permanently opt you out of any advertising programs. Unless you have settings that disallow cookies, a new cookie will be created the next time you visit a site running advertisements.
            </p>

            <p>
                For more information or if you have any questions regarding our privacy policy, please contact us at <a href="mailto:info@googamez.com">info@googamez.com</a>.
            </p>
        </Wrapper>
    );
}

export default PrivacyPolicy;
