import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    text-align: center;
`;

const GameGrid = styled.div`
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(10em, 1fr));
    @media (max-width: 1024px) {
        grid-template-columns: repeat(auto-fit, minmax(10em, 1fr));
    }
    @media (max-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 480px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

const GameItem = styled.div`
    background-color: rgb(39, 104, 203);
    border-radius: 0.5rem;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
    }

    img {
        width: 100%;
        height: auto;
        border-bottom: 0.125rem solid #f0f0f0;
    }

    h2 {
        font-size: 1rem;
        color: #333;
        padding: 0.75rem 1rem;
        margin: 0;
        background-color: #2768CB;
        color: #FFFFFF;
        text-transform: uppercase;
        letter-spacing: 0.05rem;
    }

    a {
        color: inherit;
        text-decoration: none;
        display: block;
    }
`;

function SearchResults() {
    const [games, setGames] = useState([]);
    const location = useLocation();
    const query = new URLSearchParams(location.search).get('query');

    useEffect(() => {
        if (query) {
            fetch(`/wp-json/wp/v2/posts?search=${query}`)
                .then(response => response.json())
                .then(data => setGames(data))
                .catch(error => console.error('Error fetching search results:', error));
        }
    }, [query]);

    return (
        <Wrapper>
            <h1>Search Results for "{query}"</h1>
            <GameGrid>
                {games.map(game => (
                    <GameItem key={game.id}>
                        <Link to={`/${game.categories[0]}/${game.slug}`} title={`Play ${game.title.rendered}`}>
                            <img
                                src={game.meta.thumbnail_url ? game.meta.thumbnail_url[0] : ''}
                                alt={game.title.rendered}
                            />
                            <h2>{game.title.rendered}</h2>
                        </Link>
                    </GameItem>
                ))}
            </GameGrid>
            {games.length === 0 && <p>No results found.</p>}
        </Wrapper>
    );
}

export default SearchResults;
