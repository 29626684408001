import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const NavBar = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #2768CB;
    color: #fff;
    position: sticky;
    top: 0;
    z-index: 10;
`;

const Logo = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
`;

const LogoImage = styled.img`
    width: 150px; /* Ajusta según tu diseño */
    height: auto;
`;

const NavLinks = styled.div`
    display: flex;
    gap: 1.5rem;
`;

const NavLink = styled(Link)`
    color: #fff;
    text-decoration: none;
    font-size: 1rem;
    &:hover {
        color: #F62F2F;
    }
`;

const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

const SearchInput = styled.input`
    padding: 0.5rem;
    border: none;
    border-radius: 0.25rem;
    outline: none;
`;

const SearchButton = styled.button`
    padding: 0.5rem 1rem;
    background-color: #F62F2F;
    color: #fff;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
`;

function MainMenu({ allCategories }) {
    const [categories, setCategories] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        // Filtrar las categorías que tengan más de 10 juegos, ordenar por count, y tomar las primeras 5
        const validCategories = allCategories
            .filter((category) => category.count > 10)
            .sort((a, b) => b.count - a.count)
            .slice(0, 5);

        setCategories(validCategories);
    }, [allCategories]);

    const handleSearch = () => {
        if (searchTerm.trim()) {
            navigate(`/search?query=${searchTerm}`);
        }
    };

    return (
        <NavBar>
            <Logo to="/">
                <LogoImage src="/images/logo.png" alt="GooGameZ Logo" />
            </Logo>
            <NavLinks>
                <NavLink to="/">Home</NavLink>
                {categories.length > 0 ? (
                    categories.map(category => (
                        <NavLink key={category.id} to={`/category/${category.slug}`}>
                            {category.name}
                        </NavLink>
                    ))
                ) : (
                    // Puedes mostrar enlaces vacíos o un estado de carga
                    <NavLink to="#" style={{ visibility: 'hidden' }}>
                        Loading...
                    </NavLink>
                )}
            </NavLinks>
            <SearchContainer>
                <SearchInput
                    type="text"
                    placeholder="Search games..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                />
                <SearchButton onClick={handleSearch}>Search</SearchButton>
            </SearchContainer>
        </NavBar>
    );
}

export default MainMenu;
