import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const CookieBannerContainer = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 1rem;
    font-size: 0.9rem;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.3);
    z-index: 100;
`;

const CookieButton = styled.button`
    background-color: #F62F2F;
    color: #fff;
    border: none;
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    cursor: pointer;
    margin-left: 1rem;
    transition: background-color 0.3s;

    &:hover {
        background-color: #d14a4a;
    }
`;

function CookieConsent() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (!consent) {
            setIsVisible(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'true');
        setIsVisible(false);
    };

    if (!isVisible) return <></>;

    return (
        <CookieBannerContainer>
            This website uses cookies to enhance user experience. By using this site, you accept our 
            <a href="/privacy" style={{ color: '#F62F2F', marginLeft: '0.25rem' }}>Privacy Policy</a>.
            <CookieButton onClick={handleAccept}>Accept</CookieButton>
        </CookieBannerContainer>
    );
}

export default CookieConsent;
