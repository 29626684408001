import React from 'react';
import styled from 'styled-components';

class ErrorBoundary extends React.Component {
    state = { hasError: false, error: null };    

    static getDerivedStateFromError(error) {
        return { hasError: true, error: error };
    }

    render() {
        if (this.state.hasError) {
            console.log("ERROR", this.state, this.error)
            return <></>;
            //return <h2>Something went wrong.</h2>;
        }
        return this.props.children;
    }
}

export default ErrorBoundary;